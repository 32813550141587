import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { TextBlock, Font, Section } from '@politechdev/blocks-design-system'
import ReviewTable from './ReviewTable'

const ManualReview = () => {
  const { t } = useTranslation()

  return (
    <>
      <TextBlock>
        <Font.Display variant="small" element="h2">
          {t('Review')}
        </Font.Display>
      </TextBlock>
      <TextBlock>
        <Font.Copy variant="reading-block" element="p">
          {t('Review the selected forms below before delivering.')}
        </Font.Copy>
        <Section>
          <Font.Copy variant="reading-block" element="p">
            {t(
              'Once you are done reviewing the forms, assemble the forms into a delivery package and print two copies of the delivery coversheet provided below. Attach one of the copies to the package and leave the other copy with the registrar.'
            )}
          </Font.Copy>
        </Section>
      </TextBlock>
      <ReviewTable />
    </>
  )
}

export default memo(ManualReview)
