import React, { useEffect, useState } from 'react'
import { fetchJSON, stringifyParams } from 'utils/req'
import { useTranslation } from 'react-i18next'
import {
  Button,
  ButtonBlock,
  Icon,
  useToast,
} from '@politechdev/blocks-design-system'
import { Uploader } from 'components'
import { useRequest } from 'hooks/useRequest'
import { fetchDeliveryReceipt } from 'requests/deliveries'
import { PDFDocument } from 'pdf-lib'
import { useDeliveryState, useDeliveryActions } from './DeliveryContext'

const ReceiptUploader = () => {
  const { t } = useTranslation()
  const { currentDelivery } = useDeliveryState()
  const { setCurrentDelivery } = useDeliveryActions()
  const [receiptUrl, setReceiptUrl] = useState(null)
  const { setToast } = useToast()

  const deliverDeliveryRequest = (deliveryId, receiptData) =>
    fetchJSON(
      `/api/v1/deliveries/${deliveryId}/deliver?${stringifyParams({ fields: ['status', 'runner_receipt_url'] })}`,
      'PUT',
      {
        event_args: [receiptData],
      },
      { useJwt: true }
    )

  const { makeRequest: deliverDelivery } = useRequest(deliverDeliveryRequest, {
    onSuccess: ({ delivery: { status, runner_receipt_url } }) => {
      setCurrentDelivery(currentDelivery => ({
        ...currentDelivery,
        status,
        runner_receipt_url,
      }))
    },
    onError: () => {
      setToast({
        message: t(
          'There was a problem completing your delivery. Please try again.'
        ),
        variant: 'error',
      })
    },
  })

  const handleUpload = fileData => deliverDelivery(currentDelivery.id, fileData)

  const downloadDeliveryReceipt = useRequest(fetchDeliveryReceipt, {
    onSuccess: async buffer => {
      try {
        const receipt = await PDFDocument.load(buffer)
        const download = await PDFDocument.create()
        const [p1, p2] = await download.copyPages(receipt, [0, 0])
        download.addPage(p1)
        download.addPage(p2)
        const downloadBytes = await download.save()
        const objectUrl = URL.createObjectURL(
          new Blob([downloadBytes], { type: 'application/pdf' })
        )
        window.open(objectUrl, '_blank')
        URL.revokeObjectURL(objectUrl)
      } catch {
        setToast({
          message: t(
            'There was a problem downloading the delivery receipt. Please try again or contact support.'
          ),
          variant: 'error',
        })
      }
    },
    onError: () => {
      setToast({
        message: t(
          'There was a problem downloading the delivery receipt. Please try again or contact support.'
        ),
        variant: 'error',
      })
    },
  })

  useEffect(() => {
    setReceiptUrl(currentDelivery.runner_receipt_url)
  }, [currentDelivery.runner_receipt_url])

  if (currentDelivery.status === 'completed') {
    if (receiptUrl) {
      return (
        <a href={receiptUrl} target="_blank" rel="noreferrer">
          <Button>
            <Icon.ArrowAltToBottom alt="Download" />
            <span>{t('Delivery Receipt')}</span>
          </Button>
        </a>
      )
    }
  }

  return (
    <>
      <ButtonBlock collapsed>
        <Button
          disabled={downloadDeliveryReceipt.isLoading}
          onClick={() =>
            downloadDeliveryReceipt.makeRequest(currentDelivery.id)
          }
        >
          <Icon.ArrowAltToBottom aria-label={t('Download Blank Receipts')} />
          <span>{t('Download Blank Receipts')}</span>
        </Button>
      </ButtonBlock>
      <ButtonBlock collapsed>
        <Uploader
          label={t('Upload Signed Receipt')}
          dialog
          endpoint="/system/files/upload?url=true"
          onUpload={handleUpload}
          fileTypes={['.pdf']}
          stringifyData={false}
        />
      </ButtonBlock>
    </>
  )
}

export default ReceiptUploader
