import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormattedData } from 'components'
import { TextBlock, Font } from '@politechdev/blocks-design-system'
import ReceiptUploader from './ReceiptUploader'

const DeliveryStep = ({ currentDelivery }) => {
  const { t } = useTranslation()
  const { id, turn_in_date } = currentDelivery

  return (
    <>
      <TextBlock>
        <Font.Display variant="small" element="h2">
          {t('Delivery')}
        </Font.Display>
      </TextBlock>
      <TextBlock>
        <Font.Copy variant="reading-block" element="p">
          {t('Confirm that delivery')}
          <span>
            <strong> {id}</strong>
          </span>{' '}
          {t('was delivered')} {!!turn_in_date && t('by')}{' '}
          <strong>
            <FormattedData type="date" value={turn_in_date} />
          </strong>{' '}
          {t('and upload your form delivery sheet.')}
        </Font.Copy>
      </TextBlock>
      <ReceiptUploader t={t} currentDelivery={currentDelivery} type="runner" />
    </>
  )
}

export default memo(DeliveryStep)
